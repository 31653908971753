<template>
  <div v-if="useRuntimeConfig().public.enableSmartLinks" v-bind:[atrname]="itemId" :id="id">
    <slot></slot>
  </div>
  <template v-else>
    <div>
      <slot></slot>
    </div>
  </template>
</template>
<script setup lang="ts">
const props = defineProps<{ id: string; component: boolean; customTag?: string }>()

const itemId = computed(() => props.id?.replace('_', ''))

const emits = defineEmits(['onClickAction'])
const atrname = props.component ? 'data-kontent-component-id' : 'data-kontent-item-id'
</script>
